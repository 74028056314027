import React from 'react';
import Navigation from "./navbar"
import Footer from './footer'
import Sidebar from "../components/sidebar"

import "../css/global.css"
import "../css/typography.css"


const Layout = ({children}) => {
    const [isOpen, setisOpen] = React.useState(false)
    const toggleSidebar = () => {
        setisOpen(!isOpen)
    }

    return (
        <div >
        <Navigation toggleSidebar={toggleSidebar}/>
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        {children}
        <Footer />
        </div>
    )
}

export default Layout                                   
