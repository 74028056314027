// Step 1: Import React
import * as React from 'react'
import Layout from "../components/layout"
import { StaticImage } from 'gatsby-plugin-image'


// Step 2: Define your component
const IndexPage = () =>  (

  <Layout>
  <div className='container' style={{paddingTop:'4rem', paddingBottom:'2rem' }}>

    <div className='split'>

    <div style={{margin: 'auto auto'}}>
    <h1>I bring value to digital products informed by the dimensions of design, data, risk and customer centricity.</h1> 
        <p> <span className='tags'>#data product management</span> 
            <span className='tags'>#UI/UX design</span> 
            <span className='tags'>#product strategy</span> 
            <span className='tags'>#user experience</span> 
            <span className='tags'>#risk management</span> 
            <span className='tags'>#user research</span> 
            <span className='tags'>#product analytics</span> 
            <span className='tags'>#audits</span> 
            <span className='tags'>#programming</span> 
            <span className='tags'>#governance and compliance</span> 
            <span className='tags'>#design thinking</span> 
            <span className='tags'>#systems thinking</span> 
            <span className='tags'>#tech4good</span> 
            </p>
    </div>
  
  
      <div className='imagehome'>
      <StaticImage
        alt="connecting the dots"
        src="../images/Illustration1.png"
      />

      </div> 

    </div>

  </div>   

  </Layout>
)

// Step 3: Export your component
export default IndexPage