import React from "react"
import Image from '../images/iconb.png'
import PageLinks from "../components/links"

import { StaticImage } from 'gatsby-plugin-image'

const Navigation = ({toggleSidebar}) =>  {

return (

<nav className='navbar'>
  <div className='nav-center'>
  <div className='nav-header'>
  <a class="navbar-brand" href="/" >   
  <img src={Image} alt="" width="25" height="35" className="brandlogo"/>
  <span style={{padding:'3px'}}> SHWETA NARENDERNATH </span></a>
  
  <button type='button' className='toggler-btn' onClick={toggleSidebar}>
  <div className='menubar'>
      <StaticImage
        alt="menubar"
        src="../images/menu-line.svg"
        width='25'
        height='25'
      />

      </div> 
  </button>

  <PageLinks styleClass="nav-links"></PageLinks>
  </div>
  </div>

</nav>

)
}

export default Navigation

// https://peterhironaka.com/how-to-create-a-sliding-navigation-with-react-hooks
//https://github.com/negomi/react-burger-menu#animations
// className='toggler-btn' onClick={toggleSidebar}