import React from 'react'
import Image from '../images/icon.png'
import Image1 from '../images/Glyph/VectorLi.png'
import Image4 from '../images/Glyph/Vectormd.png'
import Image3 from '../images/Glyph/Github.png'
import Image2 from '../images/Glyph/Vectortw.png'
import {
    footer,
    col1,
    col2,
    col3
} from './footer.module.css'

const Footer = () => (
    
      <footer className={footer} >

            <div className={col1}>
                  <a className="navbar-brand" href="/" style={{color:'#f3f3f3'}}>
                  <img src={Image} alt="" width="25" height="35" className="brandlogo"/>
                  <span style={{padding:'3px'}}> SHWETA NARENDERNATH </span></a>
            </div>
            
            
            <div className={col3}>
                  <a href="https://sg.linkedin.com/in/shwetanarendernath"><img src={Image1} width='20px' height='20px' padding='20px' alt='linkedin' /> </a>
                  <a href="https://medium.com/@shwetnaren"><img src={Image4} width='20px' height='20px'padding='20px' alt='medium' /></a>
                  <a href="https://github.com/shwetanaren"><img src={Image3} width='20px' height='20px' padding='20px' alt='github'/></a>
                  <a href="https://twitter.com/shwetnaren"><img src={Image2} width='20px' height='20px'alt='twitter'/></a>
            </div>

            <div className={col2} >
                  
                  
                  <p style={{color:'#ffffff', fontSize:'1rem'}}>Copyright @ Shweta Narendernath 2021. </p>
                  <p> </p>
                  <p style={{paddingTop:'0.25rem', color:'#A1A1A1', fontSize:'0.9rem'}}>Website designed, developed & maintained by me </p>
            </div>
  </footer>    
)        

export default Footer      