import React from 'react'
import Links from "../components/links"
// import Image from "../images/close-fill.svg"


const Sidebar =({isOpen, toggleSidebar}) => {
    return (
        <aside className={`sidebar ${isOpen ? "show-sidebar" : ""}`}>
            {/* <button className='close-btn' onClick={toggleSidebar}>
            <img src={Image} alt='close button' width="35" height="35" ></img>
            </button> */}
            <div className='side-container'>
                <Links styleClass={`${isOpen ? "sidebar-links" : ""}`} />
            </div>
        </aside>
    )
}

export default Sidebar
