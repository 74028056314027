import React from 'react'
import {Link} from "gatsby"

const data = [
    {
        id:1,
        text: "Home",
        url:"/",
    },
    {
        id:1,
        text: "Thoughts",
        url:"/thoughts/",
    },
    {
        id:1,
        text: "Projects",
        url:"/projects/",
    },
    {
        id:1,
        text: "About",
        url:"/about",
    },
]

const tempLinks = data.map(link => {
    return (
        <li key={link.id} style={{}}>
            <Link to={link.url}>{link.text} 
            </Link>
        </li>
    )
})


export default ({styleClass}) => {
    return (
        <ul className={`page-links ${styleClass ? styleClass: ""}`}>
            {tempLinks}
        </ul>
    )
}